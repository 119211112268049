import React from 'react';
import PropTypes from 'prop-types';
import Slug from '../slug/slug';

// eslint-disable-next-line react/prefer-stateless-function

const PodcastTileFeatured = props => {
  const { title, blurb, links, embed, image, isMobile } = props;

  if (!isMobile) {
    return (
      <div className="podcast-tile-full-featured">
        <section className="podcast-page__page-title">
          <Slug slugName={`${title}`} className="podcast-page" />
        </section>
        <h3 className="page-description">{blurb}</h3>
        <div className="podcast-tile-full-featured__links">
          {/* HANDLE KEYWEE TRACKER */}
          {links.map(link => (
            <a
              target="_blank"
              href={`${link.link}`}
              onClick={e => {
                e.preventDefault();
                if (window.top.kwa) {
                  window.top.kwa('sendCustomFBEvent', 'PodcastPlay');
                }
                window.top.open(`${link.link}`, '_blank');
              }}
            >
              <span className="podcast-tile-full-featured__link">
                <h2 className="podcast-tile__link__text">{link.platform}</h2>
              </span>
            </a>
          ))}
        </div>
        <div className="podcast-tile-full-featured__side-container">
          <img
            className="podcast-tile-full-featured__image"
            src={image}
            alt="podcast logo"
          />
          <div
            className="podcast-tile-full-featured__embed "
            dangerouslySetInnerHTML={{ __html: embed }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="podcast-page__tiles-grid">
      <div className="podcast-tile podcast-tile__featured-mobile">
        <section className="podcast-tile-full-featured__title">
          <Slug slugName={`${title}`} className="podcast-page" />
        </section>
        <h3 className="page-description podcast-tile__blurb__centered">
          {blurb}
        </h3>
        <div className="podcast-tile__links__centered">
          {/* HANDLE KEYWEE TRACKER */}
          {links.map(link => (
            <a
              target="_blank"
              href={`${link.link}`}
              onClick={e => {
                e.preventDefault();
                if (window.top.kwa) {
                  window.top.kwa('sendCustomFBEvent', 'PodcastPlay');
                }
                window.top.open(`${link.link}`, '_blank');
              }}
            >
              <span className="podcast-tile-full__link">
                <h2 className="podcast-tile__link__text">{link.platform}</h2>
              </span>
            </a>
          ))}
        </div>
        <img className="podcast-tile__image" src={image} alt="podcast logo" />
        <div dangerouslySetInnerHTML={{ __html: embed }} />
      </div>
    </div>
  );
};

PodcastTileFeatured.propTypes = {
  title: PropTypes.string.isRequired,
  blurb: PropTypes.string.isRequired,
  embed: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  links: PropTypes.shape({ root: PropTypes.array.isRequired }).isRequired
};

export default PodcastTileFeatured;
